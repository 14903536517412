<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="mb-0 page-title">
        <b-icon icon="server" />
        {{ $t("qres.title") }}
      </h1>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-form @submit.prevent="search">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              autocomplete="off"
              data-cy="search-input"
              :placeholder="$t('typeToSearch')"
              type="search"
            />

            <b-input-group-append>
              <b-button data-cy="user-clear-search-button" @click="clearFilter">
                <b-icon-x />
              </b-button>

              <b-button
                variant="primary"
                data-cy="user-search-button"
                type="submit"
              >
                {{ $t("search") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>

      <b-table
        :busy="loading"
        :current-page="currentPage"
        :fields="fields"
        :head-variant="'light'"
        :items="qres"
        :per-page="0"
        class="mb-0"
        data-cy="qres-table"
        fixed
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("loading") }}</strong>
          </div>
        </template>

        <template #cell(url)="data">
          <a
            :href="data.item.url"
            :style="
              data.item.status.toUpperCase() !== 'RUNNING' ||
              data.item.hasJupyterNotebookAccess === false
                ? 'pointer-events: none; color:gray;'
                : ''
            "
            target="_blank"
          >
            <b-icon class="mr-2" icon="terminal" />
            {{ $t("qres.openNotebook") }}
          </a>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusVariant(data.item.status)">
            {{ statusText(data.item.status) }}
          </b-badge>
        </template>
        <template #cell(console)="data">
          <div class="d-flex justify-content-end">
            <b-dropdown
              right
              size="sm"
              text="Actions"
              variant="primary"
              toggle-class="rounded-pill"
            >
              <b-dropdown-item
                :disabled="
                  data.item.status.toUpperCase() !== 'RUNNING' ||
                  data.item.hasRescueConsoleAccess === false
                "
                :to="`/qres/${data.item.qreId}/terminal`"
              >
                <b-icon class="mr-1" icon="terminal" />
                {{ $t("rescueConsole.title") }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="data.item.status.toUpperCase() !== 'RUNNING'"
                @click="openModalForFileUpload(data.item)"
              >
                <b-icon class="mr-1" icon="upload" />
                {{ $t("uploadFile.title") }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="data.item.status.toUpperCase() !== 'RUNNING'"
                :to="`/qres/${data.item.qreId}/logs`"
              >
                <b-icon class="mr-1" icon="file-text" />
                {{ $t("qres.logs") }}
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="
                  data.item.hasRestartAccess === false ||
                  (data.item.status.toUpperCase() !== 'RUNNING' &&
                    data.item.status.toUpperCase() !== 'STOPPED')
                "
                @click="restartQre(data.item)"
              >
                <b-icon-bootstrap-reboot class="mr-1" />
                {{ $t("restart") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="total > pageSize"
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="total"
        align="right"
        class="mb-0 mt-3"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />
      <b-card-text
        v-if="(qres.length === 0) & !loading"
        class="text-center my-4"
      >
        {{ $t("qres.emptyTable") }}
      </b-card-text>
    </b-card>
    <FileUploadModal :qre="qreModal" />
  </div>
</template>

<script>
import FileUploadModal from "@/components/Modals/FileUploadModal.vue";

export default {
  components: {
    FileUploadModal,
  },
  name: "QREs",
  data() {
    return {
      qreModal: {},
      qres: [],
      currentPage: 1,
      pageSize: 10,
      total: 1,
      fields: [
        {
          key: "name",
          label: this.$i18n.t("name"),
          tdClass: "align-middle qre-name whitespace-wrapper",
        },
        {
          key: "url",
          label: this.$i18n.t("qres.notebook"),
          tdClass: "align-middle",
        },
        {
          key: "status",
          label: this.$i18n.t("status"),

          tdClass: "align-middle",
        },
        {
          key: "console",
          label: "",
        },
      ],
      loading: true,
      filter: null,
      qre: {},
    };
  },
  mounted() {
    this.qreProvider();
  },
  methods: {
    async qreProvider() {
      this.loading = true;
      try {
        const {
          data: { content, page },
        } = await this.$http.get(
          this.$cfg.BASE_QRE_MANAGER_VERSION_URL + "/qres",
          {
            params: {
              page: this.currentPage - 1,
              pageSize: this.pageSize,
              search: this.filter,
            },
          }
        );

        this.total = page.totalElements;
        this.qres = content.map((el) => {
          if (el.transitioning) {
            el.status = "TRANSITIONING";
          }
          return el;
        });
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    async restartQre(qre) {
      this.qre = qre;
      try {
        this.qre.status = "TRANSITIONING";
        await this.$http.put(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qre/${qre.qreId}/restart`,
          {
            errors: {
              404: () => true,
            },
          }
        );
        this.$root.$bvToast.toast(`QRE restarted!`, {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/qres");
        }
        if (err.response) {
          this.qre.status = "UNKNOWN";
          console.log(err.response);
        }
      }
      await this.qreProvider();
    },

    search() {
      this.loading = true;
      this.currentPage = 1;
      this.qreProvider();
    },

    clearFilter() {
      this.filter = null;
      this.currentPage = 1;
      this.qreProvider();
    },

    statusVariant(status) {
      switch (status.toUpperCase()) {
        case "RUNNING":
          return "success";
        case "STOPPED":
          return "danger";
        case "TRANSITIONING":
          return "warning";
        case "UNKNOWN":
        default:
          return "secondary";
      }
    },

    statusText(status) {
      return status.toUpperCase();
    },
    openModalForFileUpload(singleQre) {
      this.qreModal = singleQre;
      this.$bvModal.show("fileUploadModal");
    },
  },
  watch: {
    "$store.getters.activeRole": function () {
      this.qreProvider();
    },
    currentPage: {
      handler: function () {
        this.qreProvider();
      },
    },
  },
};
</script>

<style></style>
