<template>
  <b-modal
    id="fileUploadModal"
    content-class="whitespace-wrapper"
    :title="$t('uploadFile.modalTitle') + ` ${qre.name}`"
    centered
    hide-footer
    size="lg"
    @hidden="resetModal"
  >
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(uploadFile)">
        <ValidationProvider
          v-slot="{ errors, valid }"
          name="File"
          rules="required"
        >
          <b-form-group
            :description="$t('uploadFile.pickFile')"
            :label="$t('uploadFile.title')"
            label-for="upload-file"
          >
            <b-input-group>
              <b-form-file
                id="upload-file"
                v-model="file"
                :drop-placeholder="$t('uploadFile.drop')"
                :placeholder="$t('uploadFile.choose')"
                :state="getValidationState(errors, valid)"
              />

              <b-input-group-append>
                <b-button
                  class="custom-danger-button"
                  style="
                    border-top-right-radius: 0.25rem;
                    border-bottom-right-radius: 0.25rem;
                  "
                  @click="removeFile"
                >
                  <b-icon icon="x" />
                </b-button>
              </b-input-group-append>

              <b-form-invalid-feedback id="live-feedback-file">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          :customMessages="messages"
          :rules="{
            regex: /^\/(?:[^\/\0\s]+\/?)*[^\/\0\s]*$/,
            required: true,
          }"
          name="File Location"
        >
          <b-form-group
            :description="$t('uploadFile.whereToUpload')"
            :invalid-feedback="errors[0]"
            :label="$t('uploadFile.location')"
            label-for="file-location"
          >
            <b-form-input
              id="file-location"
              v-model="destination"
              :placeholder="$t('uploadFile.destination')"
              :state="getValidationState(errors, valid)"
            />
          </b-form-group>
        </ValidationProvider>
        <div class="d-flex flex-row justify-content-end">
          <b-button pill type="submit" variant="primary">
            {{ $t("uploadFile.uploadBtn") }}
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { regex, required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} is required",
});

extend("regex", regex);

export default {
  name: "FileUploadModal",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    qre: {},
  },
  data() {
    return {
      file: null,
      destination: null,
      messages: {
        regex: "Invalid path. Must be an absolut path",
      },
    };
  },

  methods: {
    getValidationState(errors, valid) {
      return errors[0] ? false : valid ? true : null;
    },

    resetModal() {
      this.file = null;
      this.destination = null;
    },

    removeFile() {
      this.file = null;
    },

    async uploadFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("destination", this.destination);

      try {
        await this.$http.post(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.qre.qreId}/file-upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.$bvToast.toast("File uploaded!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });

        this.$bvModal.hide("fileUploadModal");
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },
  },
};
</script>
